.Home {
  min-height: 100vh;
  padding-bottom: 304px;
}

.Home-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 6.2rem;
  position: relative;
  /* height: 52.581rem; */
  padding-bottom: 135px;
  background: url("../../img/top_bg_img.svg");
  background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  margin-top: -6.2rem;
  @media screen and (max-width: 700px) {
    padding-bottom: 64px;
  }
}

.Home-title {
  font-weight: 600;
  font-size: 64px;
  line-height: 140%;
  margin-bottom: 24px;
}

.Home-title-section-container {
  position: relative;
  /* z-index: 2; */
  width: 100%;
  /* padding-top: 7.548rem; */
  padding-top: 6.2rem;
}

.Home-title-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Home-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  max-width: 550px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 24px;
}

.Home-benefits-section {
  background: #11151c;
}

.Home-benefits {
  padding: 64px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.Home-benefit {
  background: linear-gradient(180deg, #1d222c 0%, #151a22 100%);
  border-radius: 24px;
  padding: 24px;
  @media screen and (max-width: 700px) {
    padding: 16px;
  }
}

.Home-benefit-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin: 8px 0;
  @media screen and (max-width: 700px) {
    font-size: 16px;
  }
}

.Home-benefit-description {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
}

.Home-cta-section {
  background: #000;
}

.Home-cta-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 64px 0;
}

.Home-cta-info {
  max-width: 43.4rem;
}

.Home-cta-info__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  margin-bottom: 24px;
  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
}

.Home-cta-info__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.6);
  @media screen and (max-width: 991px) {
    margin-bottom: 24px;
  }
}

.Home-cta-options {
}

.Home-cta-option-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  margin-left: 16px;
}

.Home-cta-option-subtitle {
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: 0px;
  color: #a0a3c4;
  margin-bottom: 0.3rem;
}

.Home-cta-option-action {
  display: flex;
  justify-content: flex-end;
}

.Home-cta-button {
  display: block;
  padding: 1.5rem;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
  margin-top: 3.1rem;
}

.Home-cta-option-icon {
  display: flex;
  align-items: center;

  justify-content: center;
}

.Home-cta-option {
  width: 648px;
  max-width: 100%;
  background: linear-gradient(90deg, #390707 0%, #110202 100%);
  border-radius: 24px;
  padding: 36px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Home-cta-option-info {
  text-align: right;
}

.Home-cta-option + .Home-cta-option {
  margin-top: 4.1rem;
}

.Home-cta-option-arbitrum {
}

.Home-cta-option-bsc {
}

.Home-cta-option-ava {
}

.Home-token-card-section {
  padding-top: 64px;
  padding-bottom: 64px;
  background: #11151c;
}

.Home-token-card-info {
  max-width: 38.75rem;
}

.Home-token-card-info__title {
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  margin-bottom: 24px;
  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
}

.Home-token-card-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
}

.Home-token-card-option {
  position: relative;
  background: linear-gradient(180deg, #1d222c 0%, #151a22 100%);
  border-radius: 24px;
  padding: 24px;
  @media screen and (max-width: 700px) {
    padding: 16px;
  }
}

.Home-token-card-option-icon {
  display: flex;
  align-items: center;
  span {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-left: 4px;
  }
  margin-bottom: 12px;
}

.Home-token-card-option-icon img {
  margin-right: 0.8rem;
}

.Home-token-card-option-title {
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #fff;
}

.Home-token-card-option-apr {
  color: #fff;
  margin-top: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  span {
    color: #18bf69;
  }
}

.Home-token-card-option-action {
  margin-top: 26px;
  display: flex;
  .read-more {
    width: 120px;
  }
  .buy {
    .default-btn {
      width: 120px;
    }
  }
  @media screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    a,
    button {
      width: 100% !important;
    }
  }
}

.Home-token-card-option-action > .buy a:first-child,
.Home-token-card-option-action > .buy .a:first-child {
  margin-right: 12px;
}

/* .Home-token-card-option-action .read-more {
  float: right;
} */

.Home-latest-info-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 6.2rem;

  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    > div {
      max-width: 38rem;
    }
  }
  @media screen and (max-width: 720px) {
    > div {
      max-width: 100%;
    }
  }
}

.Home-latest-info-block {
  background: #ffffff;
  border-radius: 24px;
  padding: 24px;
  transition: 0.3s;
  max-height: 176px;
  &:hover {
    transform: scale(1.03);
  }

  @media screen and (max-width: 700px) {
    padding: 16px;
  }
}

.Home-latest-info__icon {
  margin-bottom: 16px;
}

.Home-latest-info__title {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 8px;
}

.Home-latest-info__value {
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
}

.Home-latest-info__description {
  letter-spacing: 0px;
  color: #a0a3c4;
  font-size: var(--font-sm);
  line-height: 2.5rem;
}

.Home-video-section {
  background: #101124;
  padding: 8rem 0;
}

.Home-video-block {
  background: #0b131c 0% 0% no-repeat padding-box;
  box-shadow: 0px 0.8rem 2rem #0000003d;
  border-radius: 0.7rem;
  max-width: 89.4rem;
  max-height: 50.6rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.Home-faqs-section {
  background: #101124;
  padding: 8rem 0;
}

.Home-faqs-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Home-faqs-documentation {
  margin-bottom: 4.9rem;
}

.Home-faqs-introduction__title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 3.8rem;
  line-height: 4.8rem;
}

.Home-faqs-introduction__description {
  letter-spacing: 0px;
  color: #a0a3c4;
  font-size: 1.8rem;
  line-height: 2.3rem;
  margin-bottom: 3.7rem;
  margin-top: 1.5rem;
  max-width: 29.8rem;
}

.Home-faqs-content-block {
  max-width: 80.6rem;
  width: 100%;
}

.Home-faqs-content {
  background: #17182c;
  border: 1px solid #1e2136;
  border-radius: 4px;
  cursor: pointer;
  padding: 3.7rem 7.75rem;
  width: 100%;
  max-width: 80.6rem;
}

.Home-faqs-content:hover {
  background: #17182c55;
}

.Home-faqs-content + .Home-faqs-content {
  margin-top: 1.5rem;
}

.Home-faqs-content-header {
  display: flex;
}

.Home-faqs-content-header__icon {
  display: flex;
  align-items: center;
  margin-right: 1.765rem;
  font-size: 1.3795rem;
  color: #3041da;
}

.Home-faqs-content-header__text {
  font-size: 1.7rem;
  line-height: 2.2rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.Home-faqs-content-main {
  overflow: hidden;
  height: 0px;
  visibility: hidden;
  padding: 0 0rem 0 3.1rem;
  letter-spacing: 0px;
  color: #a0a3c4;
  transition: all 0.3s ease-in-out 0s;
}

.Home-faqs-content-main.opened {
  height: fit-content;
  visibility: visible;
  padding: 1.5rem 0rem 0 3.1rem;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1000px) {
  .Home-token-card-option-action {
    > div:not(:last-child) {
      margin-right: 12px;
    }
  }
  .Home-token-card-option-action a,
  .Home-token-card-option-action .a {
    justify-content: center;
    display: inline-block;
  }
  .Home-token-card-option-action a {
    text-align: center;
  }
  // .Home-token-card-option-action > .buy {
  //   display: grid;
  //   grid-template-columns: 1fr;
  //   grid-row-gap: 1.5rem;
  // }
  .Home-token-card-option-action > .read-more {
    display: inline-block;
  }
  .Home-token-card-option-action > .buy a:first-child,
  .Home-token-card-option-action > .buy .a:first-child {
    margin-right: 0;
  }
}

@media (max-width: 700px) {
  .Home-token-card-option-action > div:not(:last-child) {
    margin-right: 0px;
  }
  .Home-token-card-options {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
  }
}

@media (max-width: 500px) {
  .Home-title-section-container {
    padding-top: 7.4rem;
  }

  .Home-title {
    font-size: 4.34rem;
    line-height: 5.58rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    letter-spacing: 0;
  }

  .Home-cta-options {
    width: 100%;
  }

  .Home-cta-option-info {
    width: 50%;
  }

  .Home-cta-option-subtitle {
    margin-bottom: 0.4rem;
  }

  .Home-cta-option-title {
    line-height: 2.4rem;
  }

  .Home-cta-option-action {
    margin-top: 0.8rem;
  }

  .Home-latest-info-container {
    gap: 16px;
  }

  .Home-latest-info__icon {
    margin-right: 2.95rem;
  }

  .Home-faqs-content {
    padding-right: 1.5rem;
  }
}

@media (max-width: 400px) {
  .Home-description {
    font-size: var(--font-base);
    line-height: 2.17rem;
    max-width: 44.4rem;

    margin-bottom: 2.6rem;
  }

  .Home-latest-info__value {
    font-size: 2.7rem;
  }
}

@media (max-width: 350px) {
  .Home-title {
    font-size: 3.875rem;
    line-height: 4.65rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    letter-spacing: 0;
  }

  .Home-latest-info__icon {
    margin: 0 1.5rem;
  }
}
