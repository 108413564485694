.modal-mint .Modal-content {
  width: 544px;
  max-width: 100%;
  @media screen and (max-width: 700px) {
    width: calc(100vw - 32px);
  }
}
.modal-mint,
.modal-success,
.modal-minting {
  @media screen and (max-width: 700px) {
    .Modal-header-wrapper {
      padding: 16px !important;
    }
    .Modal-body {
      padding: 16px;
    }
  }
}
.modal-success {
  .Modal-content {
    width: 424px;
    max-width: 100%;
    @media screen and (max-width: 700px) {
      width: calc(100vw - 32px);
    }
  }
  .success-wrap {
    .slider-wrapper {
      width: 312px;
      margin-left: auto;
      margin-right: auto;
      .slick-dots li {
        width: auto;
        height: auto;
        margin: 0 4px;
        button {
          width: auto;
          height: auto;
          &:before {
            width: auto;
            height: auto;
            font-size: 10px;
          }
        }
      }
      img {
        border-radius: 16px;

        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
      }
      .slick-dots {
        bottom: -16px;
      }
      .slick-next {
        right: 0px;
      }
      .slick-prev {
        left: -0;
      }
      .slick-arrow {
        width: 32px;
        height: 32px;
        display: flex;

        img {
          width: 32px;
          height: 32px;
        }
        .ic-next {
          transform: rotate(180deg);
        }
        &:before {
          display: none;
        }
      }
      .slick-dots li button:before {
        color: #fff;
        opacity: 0.1;
      }
      .slick-dots li.slick-active button:before {
        opacity: 1;
      }
      margin-bottom: 42px;
    }

    .nft-avt {
      width: 200px;
      height: 200px;
      margin: 16px auto;
      display: block;
    }
    .success-content {
      text-align: center;

      .success-desc {
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        margin-bottom: 24px;
        span {
          color: #fff;
        }
        > div:first-child {
          margin-bottom: 4px;
        }
      }
      .nft-name {
        font-weight: 700;
        margin-top: 8px;
      }
      .nft-info {
        display: flex;
        align-items: center;
        justify-content: center;

        margin: 4px 0 12px 0;
        height: 14px;
      }
      .nft-info-power {
        margin-right: 4px;
        &::after {
          content: "";
          width: 1px;
          height: 14px;
          background: #fff;
          margin-left: 4px;
        }

        display: flex;
        align-items: center;
      }
      .nft-power {
        span {
          color: #03f5ae;
        }
      }
      .nft-action {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        @media screen and (max-width: 700px) {
          gap: 8px;
        }
      }
    }
  }
}
.modal-minting {
  .Modal-content {
    width: 424px;
    height: 254px;
    max-width: 100%;
    @media screen and (max-width: 700px) {
      width: calc(100vw - 32px);
    }
  }
  .Modal-body {
    padding: 0;
  }
  .loading-wrap {
    text-align: center;

    max-width: 100%;
    background: #202330;
    border-radius: 16px;
    .loading-circle {
      border: 16px solid rgba(255, 255, 255, 0.1);
      border-top: 16px solid #e24647;
      border-radius: 50%;
      width: 160px;
      height: 160px;
      animation: spin 2s linear infinite;
      margin-left: auto;
      margin-right: auto;
    }
    .loading-percent {
      margin-top: 24px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  .Modal-close-button {
    display: none;
  }

  @media screen and (max-width: 700px) {
    .Modal-body {
      width: calc(100vw - 32px);
    }
  }
}

.modal-wrap {
  .mint-title {
    font-weight: 700;
    font-size: 24px;

    margin-bottom: 8px;
    @media screen and (max-width: 991px) {
      font-size: 16px;
    }
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  .mint-desc {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    @media screen and (max-width: 700px) {
      font-size: 12px;
    }
    a {
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      text-decoration: none;
      img {
        margin-left: 4px;
      }
    }
  }
  .mint-info {
    .number-nft {
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      margin-bottom: 12px;
    }
    .receiver-info {
      color: rgba(255, 255, 255, 0.6);
      span {
        color: #fff;
        margin-left: 3px;
      }

      margin: 24px 0;
      display: flex;
      justify-content: space-between;
      .your-balance,
      .your-pay {
        display: flex;
        align-items: center;
      }
      .amount-box {
        display: flex;
        align-items: center;
        .mint-stats-amount-dollar {
          position: relative;
          top: 1px;
        }
      }
      @media screen and (max-width: 700px) {
        margin: 16px 0;
        flex-direction: column;
        > div {
          &:first-child {
            margin-bottom: 8px;
          }
        }
        //   > div {
        //     width: calc(45%);
        //   }

        //   display: flex;
        //   justify-content: space-between;
        //   span {
        //     margin-left: 0;
        //   }
        //   .your-balance {
        //     margin-bottom: 0;
        //   }
        //   .your-balance,
        //   .your-pay {
        //     display: flex;
        //     flex-direction: column;

        //     align-items: flex-start;
        //     .amount-box {
        //       margin-top: 4px;
        //       display: flex;
        //       align-items: center;
        //     }
        //   }
        // }
      }
    }
    .select-box-nft {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 8px;
      .item-nft {
        height: 81px;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 16px;
        padding: 12px 8px;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        .check-ic {
          position: absolute;
          top: 7px;
          right: 11px;
        }
        &:hover {
          border: 1px solid #e24647;
        }
        .nft-price {
          color: rgba(255, 255, 255, 0.6);
        }
        .nft-price-dollar {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.6);
        }
        @media screen and (max-width: 700px) {
          height: auto;
          padding: 8px;
          .check-ic {
            top: 6px;
            right: 6px;
          }
        }
        .nft-price-dollar {
          font-size: 10px;
        }
        .nft-price {
          font-size: 12px;
        }
      }
      .item-nft--active {
        border: 1px solid #e24647;
      }
    }
    .your-balance {
      margin-bottom: 4px;

      span {
        color: #fff;
      }
    }
    .btn-primary {
      width: 100%;
      height: 48px;
      margin-bottom: 12px;
      &:hover {
        background: #04d397;
        color: #080715 !important;
      }
    }
    .mint-stats-amount-dollar {
      font-weight: 400;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 140%;
      margin-left: 4px;
    }
    .mint-stats {
      margin-bottom: 24px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      .first-box {
        margin-bottom: 16px;
      }

      .mint-stats-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        display: flex;
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 4px;
        .Tooltip {
          margin-left: 4px;
          position: relative;
        }
      }
      .mint-stats-amount {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        align-items: center;
        img {
          width: 16px;
          margin-right: 4px;
        }
      }
      @media screen and (max-width: 767px) {
        > div {
          &:not(:last-child) {
            padding-bottom: 8px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
        margin-bottom: 16px;
        gap: 8px;
        grid-template-columns: 100%;
        div {
          > div {
            display: flex;
            justify-content: space-between;
          }
        }
        .first-box {
          margin-bottom: 8px;
        }
      }
    }
  }

  .empty-wrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: rgba(255, 255, 255, 0.6);
  }
  .title-mobile {
    display: none;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .nft-modal-content {
    .mum1 {
      height: 300px;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .info-wrap {
      .nft-mobile {
        display: none;
      }
      @media screen and (max-width: 767px) {
        padding: 0;
        display: flex;
        align-items: center;
        margin-top: 16px;
        .nft-mobile {
          display: block;
          height: 80px;
        }
        .info-container {
          flex: 1;
          padding: 6px 16px 6px 0;
          > div {
            font-size: 12px !important;
            font-weight: 400;
          }

          .amount {
            font-weight: 400 !important;
            min-width: 100px;
            /* text-align: right; */
            justify-content: flex-end;
            img {
              width: 16px;
            }
          }
        }
      }
      position: relative;
      top: -3px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
      padding: 16px;
      .info-container > div {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        .amount {
          font-weight: 700;
          display: flex;
          align-items: center;
          img {
            margin-right: 4px;
          }
        }
      }
    }
  }
}
