.mint-wrapper {
  padding-top: 64px;
  // padding-bottom: 120px;
  letter-spacing: 0 !important;

  .mint-top-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;

    .nft-left-info {
      .title {
        font-weight: 600;
        font-size: 64px;
        line-height: 140%;
      }

      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        margin-top: 24px;
      }

      .buttons-actions {
        margin-top: 32px;
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .mint-stats {
        background: #11131c;
        border-radius: 24px;
        padding: 16px 24px;
        width: 589px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        margin-top: 32px;

        label {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: rgba(255, 255, 255, 0.6);
          margin-bottom: 8px;
        }
        .mint-stats-amount {
          font-weight: 700;
          font-size: 24px;
          line-height: 140%;
        }
        @media screen and (max-width: 991px) {
          width: 398px;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
          margin-top: 16px;
          padding: 16px;
          gap: 16px;
          .mint-stats-amount {
            font-size: 16px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1095px) {
    padding-top: 36px;

    .title {
      font-size: 48px !important;
    }

    .desc {
      font-size: 14px !important;
    }

    .nft-right-content {
      img {
        width: 298px;
        height: 346px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .title {
      font-size: 36px !important;

      br {
        display: none;
      }
    }

    .desc {
      font-size: 14px !important;
      margin-top: 16px !important;
    }

    .nft-right-content {
      img {
        width: 298px;
        height: 346px;
      }
    }

    .buttons-actions {
      margin-top: 16px !important;
    }

    .mint-top-banner {
      flex-wrap: wrap;
    }

    .nft-right-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.treasury-container {
  background: #11131c;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 64px;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-top: 8px;
    text-align: center;
  }

  @media screen and (max-width: 1095px) {
    margin-top: 48px;
    padding-top: 40px;
    padding-bottom: 40px;

    .title {
      font-size: 24px !important;
    }

    .desc {
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 36px;
    padding-top: 16px;
    padding-bottom: 16px;

    .title {
      font-size: 18px !important;
    }

    .desc {
      font-size: 12px !important;
    }
  }
}

.chart-wrapper {
  margin-top: 24px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  padding: 24px;

  .stats {
    display: flex;
    justify-content: space-between;

    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      color: #ffffff;
    }

    .amount {
      margin-top: 8px;
    }

    .stats-right {
      display: flex;
      gap: 24px;
    }

    .stats-line {
      .value-amount {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: rgba(255, 255, 255, 0.6);
          margin-left: 4px;
        }
      }
    }

    @media screen and (max-width: 1095px) {
      .stats-line {
        v .value-amount {
          font-size: 18px;
        }
      }
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
      gap: 24px;

      .stats-right {
        flex-direction: column;
      }

      .stats-line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .value-amount {
          text-align: right;
          font-size: 16px;
          display: flex;
          flex-direction: column;
          span {
            margin-left: 0px;
            margin-top: 4px;
            font-size: 12px;
          }
        }
      }

      .total-stake-label {
        margin-bottom: 0;
      }

      .amount {
        margin-top: 0;
      }
    }
  }

  .total-stake-label {
    margin-bottom: 13px;
  }

  .amount--large {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;

    @media screen and (max-width: 700px) {
      font-size: 16px;
    }
  }

  .amount--dollar {
    margin-left: 4px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 140%;

    @media screen and (max-width: 700px) {
      font-size: 12px;
    }
  }

  .amount {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    span {
      color: #18bf69;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 16px;
    margin-top: 16px;
    border-radius: 16px;

    .amount {
      font-size: 14px;
    }
  }
}

.common-mint-layout {
  max-width: 1096px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @media screen and (max-width: 1095px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media screen and (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.how-to-container {
  margin-top: 64px;

  .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    text-align: center;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    text-align: center;
    margin-top: 8px;
  }

  .how-to-step-container {
    margin-top: 24px;
    display: flex;
    gap: 24px;

    .step-content {
      flex: 1;
      background: #11131c;
      border-radius: 24px;
      padding: 24px;
      position: relative;
      overflow: hidden;
      height: 182px;

      .step-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
      }

      .step-desc {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 16px;
      }

      .step-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-top: 32px;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 48px);

        a {
          color: #e24647;
          text-decoration: none;
          margin-right: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          img {
            margin-left: 4px !important;
          }
        }

        .step {
          font-weight: 600;
          font-size: 64px;
          // line-height: 140%;
          position: relative;
          top: -8px;
          color: rgba(255, 255, 255, 0.1);
        }
      }
    }
  }

  @media screen and (max-width: 1095px) {
    margin-top: 48px;
    padding-top: 0;
    padding-bottom: 0;

    .title {
      font-size: 24px !important;
    }

    .desc {
      font-size: 14px;
    }

    .step-content {
      height: 202px !important;

      .step {
        font-size: 48px !important;
        right: -12px;
      }
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 36px;
    padding-top: 0;
    padding-bottom: 0;

    .title {
      font-size: 18px !important;
    }

    .desc {
      font-size: 12px !important;
    }
    .step-link {
      bottom: 16px !important;
    }
    .step-content {
      height: 202px !important;

      .step {
        font-size: 36px !important;
        right: -12px;
      }
    }

    .how-to-step-container {
      flex-wrap: wrap;
      gap: 16px;
      .step-link {
        bottom: 16px;
      }
      .step-content {
        flex: none;
        width: 100%;
        height: 146px !important;
        padding: 16px;
        &:last-child {
          height: 166px !important;
        }

        .step {
          right: -4px;
        }
      }
    }
  }
}
