.position-share-modal .Modal-content {
  min-width: 36rem;
  width: 581px;
}
.position-share-modal .actions {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.position-share-modal button,
.position-share-modal a {
  background: none;
  color: white;
  font-size: 1.45rem;
  border: none;
  opacity: 0.9;
}

.position-share-modal button:hover,
.position-share-modal a:hover {
  opacity: 1;
}

.position-share-modal button:active,
.position-share-modal a:active {
  opacity: 0.8;
}

.position-share-modal .icon {
  vertical-align: middle;
  margin-right: 0.5rem;
}

.position-share {
  aspect-ratio: 1200 / 675;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 20.5px 24px;
  gap: 12px;
}
@media screen and (max-width: 500px) {
  .position-share {
    gap : 2px;
  }
}
.position-share .logo {
  width: 147.78px;
  height: 36px;
}
.position-share .info {
  list-style: none;
  color: white;
  font-size: 1.25rem;
  display: flex;
  padding-left: 0;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  gap: 12px;
  align-items: center;
}
.position-share .info li {
  /* margin-right: 1rem; */
  padding-bottom: 0;
}
.position-share .info > .side {
  /* color: #00daff; */
  /* font-weight: 800; */
}
.position-share .info > .stroke {
  width: 0px;
  height: 20px;

  border: 1px solid rgba(255, 255, 255, 0.2);
}
.position-share .pnl {
  font-weight: 700;
  font-size: 36px;
  line-height: 140%;
  margin: 0;
}
.position-share .pnl-usd {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  margin-left: 4px;
}
.position-share .prices {
  max-width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.position-share .prices p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.6);
}

.position-share .prices p.price {
  /* font-size: var(--font-base);
  font-weight: bold; */
  /* margin-top: 0.25rem; */
  color: #FFB257;
}
.position-share .prices div {
  display: flex;
  align-items: center;
  gap: 4px;
  /* flex-direction: column; */
}
.position-share .qr-code-content {
  display: flex;
  flex-direction: column;
  /* flex-direction: column; */
}
.position-share .qr-code-content .label {
  color: rgba(255, 255, 255, 0.6) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140% !important;
}
.position-share .referral-code {
  display: flex;
  /* background: rgba(255, 255, 255, 0.05); */
  /* max-width: 18rem; */
  border-radius: 5px;
  align-items: center;
  padding: 0.5rem;
}
.position-share .referral-code div {
  display: flex;
  flex-direction: column;
}
.position-share .referral-code .label {
  font-size: 0.9rem;
  line-height: 1;
}
.position-share .referral-code .referral-code-info {
  margin-left: 1rem;
}

.position-share .referral-code img {
  width: 30px;
  height: 30px;
  margin-right: 1rem;
}
.position-share .referral-code p {
  margin: 0;
}
.position-share .code {
  font-weight: 500;
  line-height: 1;
}

.tweet-link-container.disabled {
  cursor: not-allowed;
}

.position-share-modal .App-button-option {
  justify-content: center;
}

a.App-button-option.tweet-link {
  text-decoration: none;
  width: 100%;
}
a.App-button-option.tweet-link.disabled {
  pointer-events: none;
}

.image-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
}
.image-overlay-wrapper {
  position: absolute;
  bottom: 5px;
  right: 0;
  background: #00000055;
  color: #fff;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  float: right;
  padding: 0 0.25rem;
  border-top-left-radius: 5px;
}
.image-overlay-wrapper .loading-text {
  font-size: 1.1rem;
  margin: 0 0 0 0.5rem;
}
.image-overlay {
  display: flex;
  padding: 0.5rem 0;
  justify-content: center;
  align-items: center;
}

.image-overlay .spinning-loader {
  margin-left: 0.5rem;
}

@media (max-width: 400px) {
  .position-share-modal .Modal-content {
    min-width: 28rem;
    width: 36rem;
  }
  .position-share-modal .Modal-body {
    /* margin: 1rem; */
    padding: 16px;
  }
  .position-share-modal .Modal-header-wrapper {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .position-share-modal .Modal-title-bar {
    margin: 1rem;
  }
  .Modal-title-bar {
    margin: 0 !important;
  }
  .mr-base {
    margin-right: 8px !important;
  }
  .position-share {
    padding: 1rem;
  }
  .position-share .logo {
    width: 4rem;
  }
  .position-share .info {
    font-size: 1rem;
  }
  .position-share .pnl {
    font-size: 2rem;

    line-height: 1;
  }
  .position-share .prices {
    max-width: 14rem;
  }
  .position-share .prices p {
    font-size: 0.9rem;
  }
  .position-share .prices p.price {
    font-size: 1rem;
  }
  .position-share .referral-code {
    width: 12rem;
  }
  .position-share .referral-code .label {
    font-size: 0.8rem;
  }
  .position-share .referral-code p {
    font-size: 1rem;
  }
  .position-share-modal .actions {
    margin-top: 1rem;
  }
  .position-share-modal .actions .App-button-option {
    font-size: 1rem;
    line-height: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 3rem;
  }
  .image-overlay-wrapper .loading-text {
    font-size: 1rem;
  }
}
