.ExchangeChart-top-inner {
  display: flex;
  flex-direction: row;
  gap: 24px;

  .chart-info-item {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    &:first-child {
      flex: none;
      width: fit-content;
    }
  }

  .ExchangeChart-main-price {
    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    color: #FFFFFF;
  }

  .ExchangeChart-info-label {
    opacity: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: rgba(255, 255, 255, 0.6);
  }

  .chart-token-selector {
    padding: 0 !important;

    .chart-token-selector--current {
      font-weight: 700;
      font-size: 18px;
      line-height: 140%;
      // @media screen and (max-width: 600px) {
      //   font-size: 16px;
      // }
    }
  }

}

.ExchangeChart-top {
  background: #11131C !important;
  border-radius: 16px !important;
  border: none !important;
}

.ExchangeChart-bottom {
  border-radius: 16px !important;
}

.Exchange-swap-box-inner {
  background: #11131C !important;
  border-radius: 16px !important;
  border: none !important;
  padding: 16px !important;

  .Exchange-swap-option-tabs {
    background: #202330;
    border-radius: 12px;

    .Tab-option {
      background: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.6);
      height: 44px;
      padding: 12px 16px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;

      &:hover {
        background: #FFFFFF !important;
        color: #000000 !important;
        opacity: 1;

        img {
          filter: invert(1);
        }
      }
    }

    .active {
      background: #FFFFFF !important;
      color: #000000 !important;

      img {
        filter: invert(1);
      }
    }
  }

  .Exchange-swap-order-type-tabs {
    margin: 16px 0px;
  }

  .Exchange-swap-section {
    background: #202330;
    border-radius: 12px;
    padding: 12px;
    margin-bottom: 12px;
  }

  .Exchange-swap-input {
    &::placeholder {
      color: white;
      opacity: 1;
    }
  }

  .Exchange-leverage-box {
    margin-top: 16px;

    .Checkbox {
      svg {
        margin-right: 0;
        width: 16px;
        height: 16px;
        border-radius: 4px;
      }
    }

    .selected {
      svg {
        color: white !important;
        font-size: 14px !important;
      }
    }
  }



  .Exchange-leverage-slider {
    // padding: 0;
    margin-top: 16px;
    margin-bottom: 42px;

    .rc-slider-with-marks {
      padding: 0;
    }

    .rc-slider {
      height: 1px;
    }

    .rc-slider-rail {
      background: rgba(255, 255, 255, 0.1);
      height: 1px;
    }

    .rc-slider-track {
      height: 1px;
      background: #FFFFFF;
    }

    .rc-slider-step {
      height: 1.3px;

      .rc-slider-dot {
        width: 1px;
        height: 5px;
        background: rgba(255, 255, 255, 0.1);
      }

      .rc-slider-dot-active {
        background: #FFFFFF;
      }
    }

    .rc-slider-mark {
      .rc-slider-mark-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        opacity: 0.6;
        top: -9px;

        &:last-child {}
      }
    }

    .rc-slider-handle {
      background: #11131C;
      width: 15px;
      height: 15px;
      border: 2px solid #FFFFFF;
      margin-top: -7px;
    }
  }

  .Exchange-swap-button-container {
    padding-top: 0;
    margin-bottom: 16px;
  }

  .Exchange-info-row {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Exchange-swap-market-box {
  .Exchange-swap-market-box-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #FFFFFF;
    margin-bottom: 12px;
  }

  .Exchange-info-row {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Exchange-list-tab-container {
  padding: 0px;
  margin-top: 16px;
  margin-bottom: 8px;

  .Tab-option {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
}

.Exchange-should-show-position-lines {
  // margin-top: 16px;

  .Checkbox {
    svg {
      margin-right: 0;
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }
  }

  .selected {
    svg {
      color: white !important;
      font-size: 14px !important;
    }
  }

  .Checkbox-label {
    margin-left: 4px;
  }
}

.Exchange-list {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  background: #11131C;
  border-radius: 16px;

  .App-card-options {
    button {
      min-width: 80px;
    }
  }
}

.PositionsList {


  .Tooltip {
    .Tooltip-handle {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }
  }

  .pnlText {
    font-size: 12px;
  }

}

.actions-td {
  width: 94px !important;
  padding-right: 16px !important;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .Exchange-list-action {
    color: #E24647;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    margin-right: 16px;
  }

  .btn-end {
    margin-right: 0 !important;
  }

  .PositionDropdown-dots-icon {
    color: #E24647;
  }
}

#tradingview_704c6 {
  border-radius: 16px;
}

.Exchange-list {
  .App-card-content {
    grid-row-gap: 0px;

    .App-card-row {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .App-card-divider {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .App-card-options {
    display: flex;
    gap: 12px;
    margin-top: 24px;
  }
}

.Confirmation-box-main {
  margin: 0;
  padding: 20px 12px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.Modal-header-wrapper {
  margin: 0;
  padding-top: 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;

  // @media screen and (max-width: 700px) {
  //   padding-right: 30px !important;
  // }

}

.square-container {
  margin-top: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 12px;

  .top-line {
    display: none;
  }

  .Exchange-info-row {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.PositionEditor-allow-higher-slippage,
.PositionEditor-keep-leverage-settings {
  .Checkbox {
    svg {
      margin-right: 0;
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }
  }

  .selected {
    svg {
      color: white !important;
      font-size: 14px !important;
    }
  }
}

.color-06 {
  color: rgba(255, 255, 255, 0.6);
}

.PositionEditor {
  .Exchange-swap-section {
    margin-bottom: 16px;
  }
}

.PositionsList {
  .Tab {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;

    .Tab-option {
      background: none;
      height: 44px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #FFFFFF !important;
        color: #000000 !important;
        opacity: 1;
      }
    }

    .active {
      background: #FFFFFF !important;
      color: #000000 !important;
    }
  }


}

.Modal-content {
  .Exchange-swap-section {
    background: rgba(0, 0, 0, 0.2);
    padding: 20px 12px;
    border-radius: 16px;

    .Exchange-swap-input-container {
      .Exchange-swap-max {
        height: 20px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}


.PositionDropdown-extra-options {
  .menu-items {
    background: #202330;
    border-radius: 16px;
    border: none;

    .menu-item {
      &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
}

.ExchangeChart-top-inner {
  .menu-items {
    background: #202330;
    border-radius: 16px;
    border: none;

    .menu-item {
      &:first-child {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      &:last-child {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }
  }
  // @media screen and (max-width: 600px) {
  //   gap: 16px;
  // }
}

.ExchangeChart-bottom {
  iframe {
    border-radius: 16px;
  }
}

.TradeHistory {
  background: #11131C;
  border-radius: 16px;

  .row-trade {
    border-top: solid 1px rgba(255, 255, 255, 0.1);

    &:first-child {
      border-top: 0px !important;
    }
  }
}

.Exchange-lists {
  .Exchange-list-modal {
    .square-container {
      svg {
        position: relative;
        top: 3px;
      }
    }
  }
}

.PositionEditor {
  .PositionSeller-token-selector {
    .Modal-body {
      padding: 24px !important;
      margin: 0 !important;
    }

    .Modal-header-wrapper {
      padding-right: 30px !important;
    }
  }
}


.Exchange-right {
  .TokenSelector {
    .Modal-body {
      padding: 24px !important;
      margin: 0 !important;
    }

    @media screen and (max-width: 1100px) {

      .Modal-content {
        min-height: 30vh;
      }
    }

    @media screen and (max-width: 700px) {
      .Modal-content {
        bottom: unset;
        top: 10vh;
      }
    }
  }
}

.Exchange-right {
  .Exchange-swap-section {
    .TokenSelector {
      .TokenSelector-box {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
      }

      svg {
        margin-left: 4px;
      }
    }
  }

  .PositionEditor-token-symbol {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
}

.Orders-toa-accept-rules {
  .Checkbox {
    svg {
      margin-right: 0;
      width: 16px;
      height: 16px;
      border-radius: 4px;
    }
  }

  .selected {
    svg {
      color: white !important;
      font-size: 14px !important;
    }
  }
}

.Exchange-swap-box {
  .Exchange-swap-section {
    .TokenSelector {
      position: relative;
      top: -3px;
    }
  }
}
.Exchange-swap-section {
  .TokenSelector-box {
    .TokenSelector-box-symbol {
      top: 4px;
    }
  }
}

.TokenSelector-box-symbol {
  top: 4px;
}
