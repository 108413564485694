.Referrals {
  // max-width: 100% !important;

  // min-height: 1000px !important;

  // margin-bottom: 120px !important;
  // padding-bottom: 286px !important;

  .section-title-block {
    width: 100%;
    max-width: 100%;
  }
  .section-title-content{
    width: 100%;
  }
  .referral-tab-container {
    flex: 1;
    width: 100%;
    margin: 0;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      @media (max-width: 1320px) {
        padding-left: 32px;
        padding-right: 32px;
      }

      @media (max-width: 1024px) {
        padding-left: 24px;
        padding-right: 24px;
        // margin-top: -1.5rem;
      }

      @media (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
        margin-top: -1.5rem;
      }

      .Tab {
        width: fit-content;
        border-radius: 0 0 16px 16px;

        background: #202330;

        // border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;

        .Tab-option {
          padding: 12px 24px;

          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);

          // &.active {
          //   background: #ffd400;

          //   color: #000000;
          // }
        }
      }
    }
  }

  .container-ref {
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      margin-top: 24px;
    }

    .content-ref {
      width: 1080px;

      display: flex;
      flex-direction: column;
      gap: 23px;
      @media (max-width: 1320px) {
        padding-left: 32px;
        padding-right: 32px;
      }

      @media (max-width: 1024px) {
        padding-left: 0;
        padding-right: 0;
      }

      @media (max-width: 900px) {
        flex-direction: column;
        margin-bottom: 120px;

        .section-center {
          max-width: 100% !important;
          width: 100% !important;
        }
      }

      @media (max-width: 600px) {
        // padding-left: 16px;
        // padding-right: 16px;
        gap: 16px;
      }

      .left-content {
        display: flex;

        gap: 24px;
        @media (max-width: 1024px) {
          gap: 16px;
        }
        @media (max-width: 767px) {
          flex-direction: column;
        }

        .referral-card {
          flex: 1;

          padding: 16px;

          &.has-data {
            background: #ffffff;
          }

          .top-child {
            background: radial-gradient(97.51% 922.3% at 1.61% 2.07%, #fffae2 0%, #ffe045 100%);
            border-radius: 16px 16px 0 0;

            padding: 24px;

            display: flex;

            .child {
              flex: 1;

              .sub-title {
                margin: 0;

                font-weight: 400;
                font-size: 14px;
                line-height: 140%;

                color: #000000;
              }

              .your-code-percentage {
                margin: 0;

                font-weight: 700;
                font-size: 24px;
                line-height: 130%;

                color: #000000;
              }
            }
          }

          .down-child {
            padding: 24px;

            .sub-title {
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: #000000;
            }

            .your-code-percentage {
              margin: 12px 0 16px 0 !important;

              font-weight: 700;
              font-size: 24px;
              line-height: 130%;

              color: #000000;
            }

            .modal-ref-code,
            .default-btn-custom {
              width: fit-content !important;

              background: #ffd400;

              font-weight: 500;
              font-size: 14px;
              line-height: 140%;

              color: #000000;
            }

            .description {
              margin-top: 16px;

              text-align: left;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: #000000;
            }
          }

          .top {
            padding: 24px;

            background: radial-gradient(97.51% 922.3% at 1.61% 2.07%, #fffae2 0%, #ffe045 100%);
            border-radius: 16px 16px 0 0;

            .top-title {
              margin-top: 0;

              color: #000000;
            }

            .title {
              color: #000000;
            }

            .sub-title {
              margin-bottom: 0;

              color: #000000;
            }
          }

          .down {
            padding: 24px;

            background: #ffffff;

            border-radius: 0 0 16px 16px;

            .your-code-rate {
              margin-top: 0;
            }

            .your-code-rate,
            .your-code-percentage,
            .your-code-tier {
              color: #000000;
            }
          }

          .line {
            margin: 0;
          }

          &.custom {
            padding: 24px;

            background: #ffffff;

            .title {
              color: #000000;
            }

            .sub-title {
              color: #000000;
            }
          }
        }

        .referral-stats {
          margin: 0;
          padding: 24px;
          flex: 1;
          @media (max-width: 767px) {
            padding: 16px;
          }
          display: flex;
          flex-direction: column;

          gap: 0;
          background: #11131C;
          border-radius: 16px;
          .info-card {
            flex: 1;
            padding: 0 !important;
            justify-content: flex-start;
            @media (max-width: 767px) {
              padding: 16px;
            }
            // padding: 24px;

            background: #11131C;

            &:first-child {
              // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }

            .card-details {
              display: flex;
              flex-direction: column;
              gap: 8px;

              .label {
                text-align: left;

                font-weight: 400;
                font-size: 14px;
                line-height: 140%;

                color: rgba(255, 255, 255, 0.6);

                margin: 0;
              }

              .data {
                text-align: left;

                font-weight: 700;
                font-size: 24px;
                line-height: 130%;

                color: #ffffff;

                margin: 0;
              }
            }

            &:first-child {
              border-radius: 16px 16px 0 0;
            }

            &:last-child {
              border-radius: 0 0 16px 16px;
            }
          }

          &.custom {
            .info-card {
              border-radius: 0;
              @media (max-width: 767px) {
                padding: 16px;
              }
              &:first-child {
                border-radius: 16px 16px 0 0;
              }

              &:last-child {
                border-radius: 0 0 16px 16px;

                // border-top: 1px solid rgba(255, 255, 255, 0.1);
              }
            }
          }
        }
      }

      .section-center {
        margin: 0;

        background: #11131C;
        .top-title {
          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);

          margin-bottom: 4px;
        }

        .title {
          text-align: left;

          padding: 0;
        }

        .sub-title {
          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);

          margin: 16px 0 24px 0;
        }

        .line {
          position: absolute;

          margin-left: -24px;
          width: 100%;
          height: 1px;

          background: rgba(255, 255, 255, 0.1);
        }

        .your-code-rate {
          margin-top: 48px;
          margin-bottom: 0;

          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);
        }

        .your-code-percentage {
          margin: 4px 0;

          text-align: left;

          font-weight: 700;
          font-size: 24px;
          line-height: 140%;

          color: #ffffff;

          @media (max-width: 600px) {
            font-size: 16px;
          }
        }

        .your-code-tier {
          margin-bottom: 24px;

          text-align: left;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: rgba(255, 255, 255, 0.6);
        }

        .modal-ref-code {
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 40px;

          font-weight: 500;
          font-size: 14px;
          line-height: 140%;

          color: #080715;

          background: #03f5ae;
          border-radius: 8px;
        }
      }

      .rebate-container {
        flex: 1;

        .info-card {
          justify-content: unset;
          padding: 24px;
          @media (max-width: 767px) {
            padding: 16px;
          }
          .card-details {
            h3 {
              margin-bottom: 0;

              font-weight: 400;
              font-size: 14px;
              line-height: 140%;

              color: rgba(255, 255, 255, 0.6);
            }

            .data {
              text-align: left;

              margin: 4px 0 0 0;

              font-weight: 700;
              font-size: 24px;
              line-height: 140%;

              color: #ffffff;

              @media (max-width: 600px) {
                font-size: 16px;
              }
            }
          }
        }


      }


    }
  }
}

.Exchange-swap-button.custom {
  background: #ffd400;

  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  color: #000000;
}

.text-input.custom {
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;

  color: rgba(255, 255, 255, 0.6);
}

.left-content {

  .Modal-content {
    width: 400px;
    padding: 24px;

    border-radius: 16px;

    @media (max-width: 600px) {
      width: calc(100% - 32px);
    }

    .Modal-header-wrapper,
    .Modal-body {
      margin: 0;
    }

    .divider {
      display: none;
    }

    .card-action {
      margin-top: 26px;
    }

    input {
      margin-bottom: 24px;
    }
  }
}
.rebate-container {
  .reward-history {
    margin: 0;

    .title {
      margin: 41px 0 16px 0;

      font-weight: 700;
      font-size: 24px;
      line-height: 130%;

      color: #ffffff;

      @media (max-width: 768px) {
        margin: 24px 0 16px 0;
      }
    }

    .header {
      display: flex;

      padding: 16px 24px;

      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      background: #1d0e27;
      border-radius: 16px 16px 0 0;

      .child {
        flex: 1;

        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        color: rgba(255, 255, 255, 0.6);
      }
    }

    .content {
      display: flex;

      padding: 12px 16px;
      margin-top: 0;

      background: #1d0e27;

      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-bottom: none;

        border-radius: 0 0 16px 16px;
      }

      .child {
        flex: 1;

        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        color: #ffffff;

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: #ffffff !important;
        }
      }

      &.mobile {
        flex-direction: column;
        gap: 12px;

        .mobile-wrapper {
          display: flex;
          justify-content: space-between;
          line-height: 20px;

          a {
            margin-right: 0;
            color: #ffffff;
          }
        }
      }
    }

    .empty-message {
      margin-top: 20px;
    }
  }
}

.referral-body-container {
  flex: 1;

  .info-card {
    justify-content: unset;
    padding: 24px;
    @media (max-width: 767px) {
      padding: 16px;
    }
    .card-details {
      h3 {
        margin-bottom: 0;

        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        color: rgba(255, 255, 255, 0.6);
      }

      .data {
        text-align: left;

        margin: 4px 0 0 0;

        font-weight: 700;
        font-size: 24px;
        line-height: 140%;

        color: #ffffff;
      }
    }
  }

  .reward-history {
    margin: 0;

    .title {
      margin: 41px 0 16px 0;

      font-weight: 700;
      font-size: 24px;
      line-height: 130%;

      color: #ffffff;

      &.custom {
        margin-top: 71px;
      }

      @media (max-width: 768px) {
        margin: 24px 0 16px 0;
      }
    }

    .header {
      display: flex;

      padding: 16px 24px;

      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      background: #11131c;
      border-radius: 16px 16px 0 0;

      .child {
        flex: 1;

        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        color: rgba(255, 255, 255, 0.6);

        &.no-header {
          flex: 0.6;

          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .content {
      display: flex;

      padding: 12px 16px;
      margin-top: 0;

      background: #11131c;

      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:last-child {
        border-bottom: none;

        border-radius: 0 0 16px 16px;
      }

      .child {
        display: flex;
        align-items: center;

        flex: 1;

        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        color: #ffffff;

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: #ffffff !important;
        }

        &.no-header {
          flex: 0.6;

          display: flex;
          justify-content: flex-end;
        }
      }

      &.mobile {
        flex-direction: column;
        gap: 12px;

        .mobile-wrapper {
          display: flex;
          justify-content: space-between;
          line-height: 20px;

          a {
            margin-right: 0;
            color: #ffffff;
          }
        }
      }
    }

    .empty-message {
      margin-top: 20px;
    }
  }
}
