.BuyKINGKLP {
  /* background: #101124; */
  justify-content: space-between;
}

.BuyKINGKLP-container {
  padding-top: 4.65rem;
  padding-bottom: 3.1rem;
  width: 100%;
}

.BuyKINGKLP-description {
  letter-spacing: 0px;
  color: #a9a9b0;
}

.bridge-options {
  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.5rem;
}
.buy-king {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  margin: 3.1rem 0;
}
.alternative-bridges .transfer-eth-icon {
  width: 5.58rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.direct-purchase-options {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin: 3rem 0;
  justify-content: center;
}

.KING-btn {
  display: flex;
  align-items: center;
  background: #ffffff0a;
  border: 1px solid #ffffff14;
  border-radius: 0.4rem;
  text-decoration: none;
  height: 4.65rem;
  padding: 0.8rem 1.5rem;
  margin: 3.1rem 0;
}

.KING-btn:hover {
  background: #ffffff14;
}

.KING-btn:focus,
.KING-btn:active {
  background: #ffffff1f;
}

.KING-btn-icon-block {
  display: flex;
  margin-right: 1.165rem;
}

.KING-btn-icon {
  display: flex;
  position: relative;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
}

.KING-btn-icon-network {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.KING-btn-label {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 1.85rem;
  line-height: 2.325rem;
}

.KING-block-section {
  display: flex;
  justify-content: center;
}

.display-bg {
  display: block;
}

.display-sm {
  display: none !important;
}

.card-title {
  font-size: 3.41rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.cards-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin-bottom: 2rem;
}
.card-description {
  opacity: 0.7;
  margin: 0;
}

.buttons-group {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  margin-top: 1rem;
}

.buttons-group > button,
.buttons-group > a {
  line-height: 2.325rem;
  font-size: 2rem;
}

.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-1 {
  display: inline-flex;
}

.mt-top {
  margin-top: 5rem;
}
.exchange-info-group:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media (max-width: 1200px) {
  .buttons-group {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 800px) {
  .display-bg {
    display: none !important;
  }
  .display-sm {
    display: block !important;
  }
  .card-details {
    display: flex;
    flex-direction: column;
  }
  .section-title-block {
    margin-bottom: 1.5rem !important;
  }
  .cards-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px) {
  .BuyKINGKLP-description {
    font-size: var(--font-base);
    line-height: 2rem;
  }
  .buttons-group {
    grid-template-columns: auto;
  }
  .col-1 {
    display: grid;
  }
}

@media (max-width: 370px) {
  .cards-row {
    grid-template-columns: auto;
  }
}
