.asset-menu-items {
  position: absolute;
  border: 1px solid #32344c;
  border-radius: 0.4rem;
  background: var(--dark-blue);
  outline: none;
  z-index: 100;
}

.center-both {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-arrow {
  margin-left: 4px;
  cursor: pointer;
  color: white;
}
.dropdown-arrow:hover {
  opacity: 1;
}

.asset-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #a0a3c4;
  text-decoration: none;
  padding: 0.85rem 0.8rem;
  font-size: var(--font-sm);
}
.asset-item:hover {
  background: var(--dark-blue-hover);
  color: white;
  border-radius: 0.4rem;
}
.asset-item p {
  margin: 0;
  margin-left: 0.5rem;
}
.asset-item .asset-item-icon {
  max-height: 1.6rem;
}
