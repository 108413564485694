.CompleteAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
}
@media screen and (max-width: 700px) {
  .CompleteAccountTransfer .Page-description {
    word-break: break-word;
  }
}
.CompleteAccountTransfer .Page-title {
  margin-bottom: 24px;
}
