.nft-wrapper {
  .pagination-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 700px) {
      justify-content: center;
    }
    .ant-pagination-jump-next {
      background: #202330;
      border-radius: 8px;
    }
    .ant-pagination-item-ellipsis {
      color: #fff;
    }
    .ant-pagination-item-link {
      width: 32px;
      height: 32px;
      background: #202330;
      border-radius: 8px;
      svg {
        fill: #fff;
      }
      &:hover {
        filter: brightness(1.2);
        background: #202330;
      }
    }
    .ant-pagination-item {
      background: #202330;
      border-radius: 8px;

      a {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        width: 32px;
        height: 32px;
        color: #fff;
        font-family: "Lexend Deca";
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        filter: brightness(1.2);
        background: #202330;
      }
    }
    .ant-pagination-item-active {
      background: #e24647 !important;
      border: none;
    }
  }
  .empty-nft {
    height: 352px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .empty-txt {
      margin: 12px 0;
    }
    .link-mint {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #e24647;
      display: flex;
      align-items: center;
      text-decoration: none;
      img {
        margin-left: 4px;
      }
      &:hover {
        filter: brightness(1.2);
      }
    }
    @media screen and (max-width: 700px) {
      background: #11131c;
      border-radius: 24px;
    }
  }
  .d-none {
    @media screen and (max-width: 700px) {
      display: none !important;
    }
  }
  .bannerNFT {
    max-width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    object-fit: cover;

    height: 390px;
  }

  @media screen and (max-width: 1200px) {
    .bannerNFT {
      object-position: 68% center;
    }
  }
}

//infoNFT
.nft-wrapper .total {
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 2;
  position: relative;
  @media screen and (max-width: 991px) {
    gap: 24px;
  }
}
.nft-wrapper {
  .top {
    display: flex;
    gap: 24px;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right > .label {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;

    color: #ffffff;
  }

  @media screen and (max-width: 600px) {
    .right > .label {
      font-weight: 700;
      font-size: 24px;
      line-height: 140%;

      color: #ffffff;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;

      color: rgba(255, 255, 255, 0.6);
    }
  }

  .description {
    margin: 4px 0 12px 0;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }

  .docs {
    display: flex;
    align-items: center;

    width: fit-content;

    padding: 8px 16px;
    gap: 4px;

    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;

    font-weight: 500;
    font-size: 14px;
    line-height: 140%;

    color: #ffffff;
  }

  .bottom {
    display: flex;

    padding: 24px;

    width: 100%;

    background: #1b2b45;
    border-radius: 16px;
  }

  .child {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 8px;
  }

  @media screen and (max-width: 600px) {
    .top > img {
      width: 140px;
      height: 140px;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;

      color: rgba(255, 255, 255, 0.6);
    }

    .bottom {
      flex-direction: column;

      padding: 12px 16px;
      gap: 6px;
    }

    .child {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }

  .value {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: #ffffff;
  }

  //join crew

  .your-nft {
    width: 1144px;

    color: #fff;

    .commonInfo,
    .yourInfo {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      > div {
        > div:first-child {
          margin-bottom: 16px;
        }
      }
    }
    .commonInfo {
      @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        padding: 16px !important;
        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-bottom: 0 !important;
          gap: 16px;
          > div:first-child {
            margin-bottom: 0px;
          }
        }
      }
    }
    .yourInfo {
      grid-template-columns: auto auto 72px;
      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(3, 1fr);
        .btnWrap {
          button {
            width: 100%;
          }
        }
      }
      @media screen and (max-width: 700px) {
        padding: 16px 16px 60px 16px !important;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        .btnWrap {
          button {
            position: absolute;
            width: calc(100vw - 64px);
            bottom: 16px;
          }
        }
      }
    }

    .title-nft {
      font-weight: 700;
      font-size: 36px;
      line-height: 140%;
      margin-bottom: 4px;
      color: #ffffff;
      text-align: center;
    }
    .desc {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff;
      margin-bottom: 64px;
      text-align: center;
    }

    .joinLayout {
      margin-top: 8px;
      display: grid;
      background: #11131c;
      border-radius: 24px;
      padding: 24px;
      gap: 24px;
      @media screen and (max-width: 700px) {
        padding: 0px;
        background: #000;
      }

      .nft-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .nft-action-btns {
          button:first-child {
            margin-right: 12px;
          }
        }
        @media screen and (max-width: 700px) {
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          position: fixed;
          bottom: 0;
          z-index: 2;
          background: #272b3d;
          box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.6);
          padding: 16px;
          left: 0;
          .nft-action-btns {
            margin-top: 16px;
            width: 100%;
            > button {
              width: calc(50% - 6px);
            }
          }
        }
        > div {
          display: flex;
          .select-action {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            margin-left: 12px;
            color: #e24647;
            cursor: pointer;
            &:hover {
              filter: brightness(1.2);
            }
          }
        }
      }
    }
    .label {
      color: rgba(255, 255, 255, 0.6);
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      margin-bottom: 8px;
    }
    .value {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      display: flex;

      color: #fff;
    }
    .value-apr {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #18bf69;
    }
    .dollarValue {
      color: rgba(255, 255, 255, 0.6);
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      margin-left: 8px;
    }
    .btnWrap button {
      margin-left: auto;
    }
    .info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
      @media screen and (max-width: 991px) {
        grid-template-columns: 1fr;
      }
    }
    .info > div {
      background: #202330;
      backdrop-filter: blur(35px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 24px;
      padding: 24px;
    }

    .listNFT {
      position: relative;
    }
    .listTitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
    }
    .list {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      gap: 12px;
      @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
      @media screen and (max-width: 700px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
    .nftItem {
      padding: 16px;
      position: relative;
      background: #202330;
      border-radius: 16px;
      &.nftItem-checked {
        background: #11131c;
        /* Red */

        border: 1px solid #e24647;
      }
    }
    .nftItem img.nft-avt {
      border-radius: 16px;
    }
    .item {
      display: flex;
      flex-direction: column;

      font-weight: 400;
      font-size: 12px;
      line-height: 140%;
      .nft-info {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        .nft-info-power {
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }

    .checkItem {
      position: absolute;
      top: 14px;
      left: 10px;
      img {
        cursor: pointer;
        width: 20px;
      }
    }
    @media screen and (max-width: 991px) {
      .desc {
        margin-bottom: 48px;
      }
      .item .nft-info {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 700px) {
      .title-nft {
        font-size: 18px;
      }
      .desc {
        margin-bottom: 36px;
        font-size: 12px;
      }
      .info {
        grid-template-columns: 1fr;
        gap: 16px;
      }
      .listNFT {
        padding: 0px;
        border-radius: 16px 16px 0 0;
      }
    }
  }

  //mint

  .mint-wrapper {
    margin: 48px 0 72px 0;
    @media screen and (max-width: 991px) {
      margin: 40px 0;
    }
  }
  .info-img {
    cursor: pointer;
  }

  .label {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    color: #ffffff;
  }

  .description {
    margin: 4px 0 24px 0;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }

  .nftBox {
    display: grid;

    grid-template-columns: 1fr 1fr;
    padding: 24px;
    gap: 24px;

    width: 100%;

    background: #09101c;
    border-radius: 16px;
  }

  @media screen and (max-width: 991px) {
    .nftBox {
      display: flex;

      flex-direction: column;
    }
  }
  @media screen and (max-width: 700px) {
    .nftBox {
      padding: 16px;
    }
  }
  .left {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .left > img {
    width: 200px;
  }

  .right {
    flex: 1;

    padding: 24px;

    height: 100%;

    background: #010815;
    border-radius: 24px;
  }

  .current {
    display: flex;

    margin: 24px 0 16px 0;

    width: 100%;
  }

  .next {
    display: flex;

    width: 100%;
  }

  .child {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    display: flex;
    align-items: center;

    gap: 6px;

    font-weight: 400;
    font-size: 12px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }

  .title > img {
    width: 12px;
  }

  .value {
    display: flex;
    align-items: center;

    gap: 4px;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: #ffffff;
  }

  .value > img {
    width: 20px;
    height: 20px;
  }

  .titleSelect {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.7);
  }

  .select {
    position: relative;

    display: flex;
    gap: 12px;
  }

  .select > .stick {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 1px;
    height: 90px;

    background: rgba(255, 255, 255, 0.1);
  }

  .mint {
    position: relative;

    display: flex;
    gap: 12px;

    margin-top: 16px;
  }

  .mint > .stick {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 1px;
    height: 121px;

    background: rgba(255, 255, 255, 0.1);
  }

  @media screen and (max-width: 600px) {
    .right {
      padding: 16px 12px !important;
    }
    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;

      color: rgba(255, 255, 255, 0.6);
    }

    .value {
      font-weight: 400;
      font-size: 12px;
      line-height: 140%;

      color: #ffffff;
    }

    .select > .stick {
      top: 24px !important;
      left: 12px !important;
      height: 96px !important;
    }

    .mint > .stick {
      top: 24px !important;
      left: 12px !important;
      height: 146px !important;
    }

    .right {
      padding: 16px 12px;
    }

    .select,
    .mint {
      gap: 0;
    }

    .mint > .content > .boxSelect {
      padding: 12px 8px;
      gap: 8px;
    }

    .mint > .content > .boxSelect > .center:nth-child(2) {
      flex: 1.2;
    }

    .mint > .content > .boxSelect > .center:nth-child(3) {
      align-items: flex-end !important;
    }

    .mint > .content > .boxSelect > .center > .centerBottom {
      font-size: 12px !important;
    }

    .titleSelect {
      padding-left: 12px;
    }

    .box {
      width: 24px !important;
      height: 24px !important;
    }
    .content {
      margin-top: 0 !important;
    }
  }

  .mint > .content > .titleSelect {
    display: flex;
    justify-content: space-between;
  }

  .mint > .content > .boxSelect {
    justify-content: unset;
  }

  .mint > .content > .boxSelect > .center {
    flex: 1;

    display: flex;
    flex-direction: column;

    align-items: flex-start;
    gap: 4px;
  }

  .mint > .content > .boxSelect > .center > .centerTop {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }

  .mint > .content > .boxSelect > .center > .centerBottom {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: #ffffff;
  }

  .content {
    margin-top: 10px;

    flex: 1;
  }

  .btnMint {
    margin-top: 20px;
    margin-left: 52px;

    cursor: pointer;

    width: calc(100% - 52px);

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 4px;

    border: none;

    border-radius: 12px;

    font-weight: 500;
    font-size: 14px;
    line-height: 140%;

    color: #ffffff;
  }

  .titleSelect {
    margin-bottom: 10px;
  }

  .boxSelect {
    display: flex;
    justify-content: space-between;
  }

  .minus,
  .plus {
    display: flex;
    align-items: center;
  }

  .minus,
  .plus > img {
    cursor: pointer;
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .centerTop {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }

  .centerBottom {
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.6);
  }

  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    width: 40px;
    height: 40px;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
  }

  .boxSelect {
    padding: 12px;
    gap: 12px;

    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
  }
  .nft-box {
    background: #11131c;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 24px;
    margin-top: 36px;
    .mint-more {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #e24647;
      text-decoration: none;
      display: flex;
      align-items: center;
      img {
        margin-left: 4px;
        margin-top: -3px;
      }
      &:hover {
        filter: brightness(1.2);
      }
    }

    > div:first-child {
      display: flex;
      .listTitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.6);
        span {
          font-weight: 700;
          font-size: 16px;
          color: #fff;
        }
        &:first-child {
          margin-right: 24px;
        }
      }
    }
    @media screen and (max-width: 700px) {
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      .mint-more {
        margin-top: 16px;
      }
    }
  }
}
