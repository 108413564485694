.BuyKINGKLP {
  background: none;
  justify-content: space-between;
}

.BuyKINGKLP-container {
  padding: 0 0 3.1rem 0;
  width: 100%;
}

.BuyKINGKLP-container .section-title-content {
  justify-content: flex-start;
}
