.menu-items:focus-visible {
  border: 1px solid #262638;
}
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.user-address {
  margin-left: 8px;
  margin-right: 8px;
}

.App-header-user-address:hover {
  background: #808aff14;
}
.App-header-user-address:active {
  background: var(--dark-blue-active);
}

.menu-items {
  position: absolute;
  right: 0;
  top: 47px;
  min-width: 225px;
  width: 100%;
  transform-origin: top right;
  background: #202330;
  border-radius: 24px;
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  padding: 12px 16px;
  color: #ffffff;
}

.menu-item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.menu-item:hover {
  background: #808aff14 !important;
  border-radius: 0.4rem;
  opacity: 1;
  color: #eee;
}
.menu-item > p {
  margin: 0px;
  padding-left: 1rem;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .address-btn {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (max-width: 700px) {
  .user-avatar {
    display: none;
  }
  .user-address {
    margin-left: 0;
  }
}
