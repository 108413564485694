html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 10px;
  background: #000000;
}

body {
  margin: 0;
  font-family: "Lexend Deca", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Lexend Deca";
}

* {
  box-sizing: border-box;
  font-family: "Lexend Deca", sans-serif;
  /* letter-spacing: 0.05rem; */
}

input {
  font-family: "Lexend Deca", sans-serif;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c1c1c;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #383838;
  border-radius: 1.5rem;
  border: 1px solid #212121;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4a4a4a;
}

:root {
  --dark-blue: #17182c;
  --dark-blue-bg: #16182e;
  --dark-blue-accent: #a0a3c4;
  --primary-btn-bg: #2d42fc;
  --primary-btn-hover: #4f60fc;
  --primary-btn-active: #5f6ffd;
  --dark-blue-hover: #808aff14;
  --dark-blue-border: #ffffff29;
  --dark-blue-active: #818cef33;
  --avax-red: #e84143;
  --arbitrum-blue: #28a0f0;
  --border-radius-sm: 0.4rem;
  --error-red: #fa3c58;
  --font-sm: 1.4rem;
  --font-base: 1.5rem;
  --font-md: 1.6rem;
  --font-lg: 2.4rem;
  --font-xl: 3.4rem;
}

a {
  color: white;
}

.Card-title {
  font-size: 3.1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 2rem;
  color: white;
  outline: none;
  background: none;
  border: none;
  padding: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

button {
  outline: none;
  cursor: pointer;
}

button:disabled,
button.App-cta:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  box-shadow: none;
}

table {
  margin: 0;
  padding: 0;
}

tr {
  margin: 0;
  padding: 0;
}

td {
  margin: 0;
  padding: 0;
  padding-right: 0.465rem;
}

.border {
  border: 1px solid rgba(163, 165, 196, 0.2);
}

.align-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.muted {
  opacity: 0.6;
}

.strong {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.relative {
  position: relative;
}

.margin-bottom {
  margin-bottom: 0.465rem;
}

.transition-arrow {
  vertical-align: middle;
  margin-top: -0.31rem;
}

.inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.text-white {
  color: white;
}

.label,
.label a {
  color: rgba(255, 255, 255, 0.6);
}

ul {
  padding-left: 2.325rem;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 1.5rem;
}

a.plain {
  text-decoration: none;
}

.checkbox:checked:before {
  background-color: green;
}

.default-btn {
  background: #e24647;
  border-radius: 100px;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 16px;
  line-height: 140%;
  letter-spacing: 0px;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.default-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box !important;
  cursor: no-drop !important;
}
.default-btn:hover {
  background: #ff6062;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.default-btn:active {
  background: #ff6062;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.border-btn {
  border: 1px solid #d23e40;
  background: none;
  border-radius: 100px;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0px;
  padding: 12px 16px;
  color: #ffffff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.border-btn:hover {
  border: none;
  background: #ff6062;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.border-btn:active {
  border: none;
  background: #ff6062;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.long-btn {
  background: #18bf69;
  border-radius: 100px;
  height: 44px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  padding: 12px 16px;
  letter-spacing: 0px;
  color: #000000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}

.long-btn:hover {
  background: #25f087;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.long-btn:active {
  background: #25f087;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.long-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff !important;
}
.default-btn:disabled,
.outline-btn:disabled,
.normal-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

.default-container {
  width: 1368px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  max-width: 100%;
}
.buy-lp-page {
  width: 1248px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  max-width: 100% !important;
}
.outline-btn {
  height: 44px;
  padding: 12px 16px;
  border: 1px solid #e24647;
  background: none;
  border-radius: 100px;
  color: #e24647 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-decoration: none;
  display: flex !important;
  justify-content: center;
  background: transparent;
  align-items: center;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.normal-btn {
  background: transparent;
  height: 44px;
  padding: 12px 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
  transition: 0.3s;
}
.normal-btn:hover {
  border: 1px solid #e24647;
}
.outline-btn:hover {
  filter: brightness(1.3);
  color: #fff;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 1, 1);
}
.secondary-btn {
  background: #03d1cf;
  border-radius: 4px;
  font-size: var(--font-sm);
  line-height: 2rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: black;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 3.6rem;
  border: none;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
}

.secondary-btn:hover {
  background: #3fdad9;
}

.secondary-btn:focus {
  background: #54dedd;
  border: 1px solid #1cb5b4fc;
  box-sizing: border-box;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.secondary-btn:active {
  background: #54dedd;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  /* padding: 9px 16px; */
}

.secondary-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 5.2rem;
  height: 5.2rem;
  position: absolute;
  left: calc(50% - 2.6rem);
  top: -0.8rem;
  background: white;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 500ms linear;
}

.secondary-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

@keyframes ripple {
  to {
    transform: scale(2);
    opacity: 0.4;
  }
}

button.btn-link {
  margin-top: 1.5rem;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font-size: var(--font-base);
  display: block;
  text-align: left;
}

.logout-icon {
  vertical-align: middle;
  font-size: 1.7rem;
  margin-top: -0.31rem;
  margin-right: 0.5rem;
  margin-left: -0.465rem;
  opacity: 0.8;
}

button.btn-link:hover {
  text-decoration: underline;
}

button.btn-link:hover .logout-icon {
  opacity: 1;
}

.page-container {
  padding-top: 6.2rem;
}

.default-sm-container {
  max-width: 108.8rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-sm {
  margin-top: 1rem;
}
.mt-md {
  margin-top: 1.5rem;
}

.page-layout {
  min-height: calc(100vh - 350px);
  display: flex;
  flex-direction: column;
  /* padding-bottom: calc(4.65rem + 20rem); */
  padding-top: 4.65rem;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.font-sm {
  font-size: var(--font-sm);
}
.font-base {
  font-size: var(--font-base);
}
.mr-base {
  margin-right: 1.5rem;
}
.default-cursor.App-cta {
  cursor: default;
}
.nowrap {
  white-space: nowrap;
}

.gap-right > *:not(:last-child) {
  margin-right: 1rem;
}

@media screen and (max-width: 700px) {
  .default-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-space-between {
  justify-content: space-between;
}
.flex-column {
  flex-direction: column;
  display: flex;
}
.gap-8 {
  gap: 8px;
}
