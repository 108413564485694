.TokenSelector .Modal .Modal-content {
  width: 42rem;
  position: absolute;
  max-height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border: none;
}

.TokenSelector .Modal .Modal-content .Modal-body {
  scroll-margin-right: 38.75rem;
  overscroll-behavior: smooth;
  scrollbar-width: thin;
  /* padding-right: 0.5rem; */
  max-height: calc(100vh - 13rem);
  overflow-y: auto;
}

.TokenSelector-token-name {
  margin-right: 1.5rem;
}

.TokenSelector.disabled {
  pointer-events: none;
}

.TokenSelector.disabled .TokenSelector-caret {
  display: none;
}

.TokenSelector-box {
  display: block;
  cursor: pointer;
  border-radius: 0.6rem;
  text-align: right;
  white-space: nowrap;
}

.TokenSelector-box:hover {
  color: #e24647;
}

.Token-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Token-info .Token-symbol {
  margin-left: 0.8rem;
  display: flex;
  flex-direction: column;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.Token-info .token-logo {
  display: block !important;
  width: 4rem;
  height: 4rem;
  margin-right: 2px;
  margin-left: 0 !important;
}

.TokenSelector-caret {
  vertical-align: middle;
  display: inline-block;
  margin-left: 0.465rem;
  font-size: var(--font-lg);
  margin-top: -0.465rem;
}

.TokenSelector-token-row {
  cursor: pointer;
  border: 1px solid #ffffff29;
  position: relative;
  background: transparent;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  margin-bottom: 0.8rem;
}

.TokenSelector-tooltip.Tooltip {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.TokenSelector-tooltip-backing {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
}

.TokenSelector-token-row.disabled {
  cursor: not-allowed;
}

.TokenSelector-token-row.disabled .Token-info {
  opacity: 0.3;
}

.TokenSelector-token-row.disabled .Token-balance {
  opacity: 0.3;
}

.TokenSelector-token-row.TokenSelector-token-input-row {
  padding: 0;
  margin-top: 1.5rem;
}

.TokenSelector-token-row input {
  padding: 1rem 1rem 1rem 4rem;
  width: 100%;
}

.TokenSelector-token-row .Token-text {
  font-size: var(--font-md);
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0px;
}

.TokenSelector-token-row:hover {
  background: var(--dark-blue-hover);
}

.TokenSelector-token-row:active {
  background: var(--dark-blue-active);
}

.TokenSelector.right .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}
.text-accent {
  color: var(--dark-blue-accent);
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0;
}

.TokenSelector.right .Modal-content {
  position: absolute;
  width: 35.65rem;
  top: 0;
  bottom: 0;
  overflow: auto;
  border: none;
}

.Tokenselector-search-input {
  font-size: 1.6rem;
  background-position: 12px center;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-attachment: scroll;
}
.Tokenselector-search-input::placeholder {
  color: var(--dark-blue-accent);
}

@media (max-width: 1100px) {
  .TokenSelector .Modal .Modal-content {
    max-height: 80vh;
    min-height: 80vh;
    top: auto;
    bottom: auto;
  }

  .TokenSelector .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 13rem);
    /* padding-right: 0.5rem; */
    /* margin: 1.5rem 0.8rem 1.5rem 1.5rem; */
  }
}

@media (max-width: 700px) {
  .TokenSelector .Modal .Modal-content {
    bottom: 0;
  }
  .TokenSelector .Modal .Modal-content .Modal-body {
    padding-top: 16px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .TokenSelector.right .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 3.1rem;
    top: 0;
    right: 3.1rem;
    bottom: 0;
  }
  .Token-info .token-logo {
    width: 3rem;
    height: 3rem;
    margin-right: 0.8rem;
  }
}
